import moment from 'moment';

const pattern = {
  password: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).+$',
  username: '^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._@]+(?<![_.])$',
  email: /^[^\s]+[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?[^\s]+$/
}

const checksum = (value, arr, startAt = 0) => {
  let sum = 0;
  let str = value.toString();
  for (let i = startAt; i < (startAt + arr.length); i++) {
    sum = sum + (str[i] * arr[i - startAt]);
  }
  return sum % 11;
};

export const bulstat9 = value => {
  if (value.match(/\b\d{9}\b/)) {
    let str = value.toString();
    const res = checksum(value, [1, 2, 3, 4, 5, 6, 7, 8]);
    if (res !== 10) {
      return str[8] === res.toString();
    } else {
      const res = checksum(value, [3, 4, 5, 6, 7, 8, 9, 10]);
      if (res !== 10) {
        return str[8] === res.toString();
      }
      return str[8] === '0';
    }
  }
  return false;
};

export const bulstat13 = value => {
  if (value.match(/\b\d{13}\b/)) {
    let str = value.toString();
    let n8;
    const res = checksum(value, [1, 2, 3, 4, 5, 6, 7, 8]);
    if (res !== 10) {
      n8 = res.toString();
    } else {
      const res = checksum(value, [3, 4, 5, 6, 7, 8, 9, 10]);
      if (res !== 10) {
        n8 = res.toString();
      } else {
        n8 = '0';
      }
    }
    if (str[8] !== n8) {
      return false;
    } else {
      let n12;
      const res = checksum(value, [2, 7, 3, 5], 8);
      if (res !== 10) {
        n12 = res.toString();
      } else {
        const res = checksum(value, [4, 9, 5, 7], 8);
        if (res !== 10) {
          n12 = res.toString();
        } else {
          n12 = '0';
        }
      }
      return n12 === str[12];
    }
  }
  return false;
}

export const egn = value => {
  if (value.match(/\b\d{10}\b/)) {
    let str = value.toString();
    const res = checksum(value, [2, 4, 8, 5, 10, 9, 7, 3, 6]);
    if (res === 10 || res === 0) {
      return str[9] === '0';
    }
    return str[9] === res.toString();
  }
  return false;
}

export function required(value) {
  return (value != null && value !== '') ? undefined : 'Полето е задължително';
}

export function requiredTrue(value) {
  return value === true ? undefined : 'Полето е задължително';
}

export function requiredMulti(value) {
  return value && value.length ? undefined : 'APP.COMMON.FORM.ATLEAST_ONE_VALUE';
}

export function requiredIfOther(field) {
	return (value, allValues) => allValues[field] ? required(value) : undefined;
}

export function minLength(min) {
  return value => value.length >= min ? undefined : {text: 'APP.COMMON.FORM.LEAST_SYMBOLS', values: [min]};
}

export function maxLength(max) {
  return value => value.length <= max ? undefined : {text: 'APP.COMMON.FORM.MOST_SYMBOLS', values: [max]};
}

export function minValue(min) {
  return value => value >= parseFloat(min) ? undefined : { text: 'Минималната сума е {0} лева', values: [min] };
}

export function minNumber(min) {
  return value => value >= parseFloat(min) ? undefined : { text: 'Минималният брой е {0}', values: [min] };
}

export function numberRange(min, max) {
  return value => value >= min && value <= max ? undefined : { text: 'Трябва да въведете число между {0} и {1}', values: [min, max] };
}

export function matchPattern(type, message) {
  return value => value && value.match(pattern[type]) ? undefined : message;
}

export function maxSymbolCount(symbol, max) {
  return value => value.split(symbol).length - 1 <= max ? undefined : {text: 'APP.COMMON.FORM.MOST_SYMBOLS_TYPEтип "{1}"', values: [max, symbol]};
}

export function axlesNumberValidation(min, max) {
  return value => (value < min || value > max) ? {text: 'Броят оси трябва да е между {0} и {1}', values: [min, max] } : undefined;
};

export function ibanValidation(input) {
  const countryCode = typeof input === 'string' && input.trim().substr(0, 2);
  return countryCode && regexIban[countryCode] && regexIban[countryCode].test(input) ? undefined : 'Невалиден IBAN'
}

export function ibanListValidation(input) {
  if (input && input.length > 0) {
    const invalid = 'Невалиден IBAN';
    const list = Array.isArray(input) ? input : input.split(',');
    const listValidationResults = list.map(iban => ibanValidation(iban.trim()));

    if (listValidationResults.includes(invalid)) {
      return invalid;
    }

    return undefined;
  }
}

export function floatDigitsNullAllowed(value) {
  return /^[0-9.]+$/.test(value) ? undefined : 'Грешен формат';
}

export function floatDigits(value) {
  return value && /^[0-9.]+$/.test(value) ? undefined : 'Грешен формат';
}

export function digitsOnly(value) {
  return value && /^[0-9]+$/.test(value) ? undefined : 'Грешен формат';
}

export function composeValidators (...validators) {
  return value => validators.reduce((error, validator) => error || validator(value), undefined);
}

export const composeValidatorsAllValues = (...validators) => (value, allValues, fieldState) =>
  validators.reduce((error, validator) => error || validator(value, allValues, fieldState), undefined);

export function dateNotPast(value) {
  return moment(value).isAfter(moment(), 'minutes') ? undefined : 'Не може да избирате дата в миналото';
}

const cyrillicKeyMap = {
	'А':'A', 'В': 'B', 'Е': 'E', 'К': 'K', 'М': 'M', 'Н': 'H', 'О': 'O', 'Р':'P', 'С':'C', 'Т':'T', 'У':'Y', 'Х': 'X'
}

export function normalizeLpn (value) {
	return value ? value.toUpperCase().replace(/[^ÜÖÄČŠŽĆĐАВЕКМНОРСТУХA-Z0-9]/g, "").split('').map(a => cyrillicKeyMap[a] || a).join('') : '';
}

export function validatePhone(value) {
  return value && regexPhone.test(value) ? undefined : 'Грешен формат';
}

export function validateIfAddSomePhone(value) {
  if (typeof value === "undefined") return undefined;
  return (value && value.length > 4 && regexPhone.test(value)) ? undefined : 'Грешен формат';
}

export function validateEmail(value) {
  return value && regexEmail.test(value) ? undefined : 'Грешен формат';
}

export function validateIdent(value, type) {
  if (type*1 === 3) {
    return value && egn(value) ? undefined : 'APP.COMMON.VALIDATION.ID';
  } 
  if (type*1 === 5) {
    // TODO re-enable validation when added validation for 10 digits
    return undefined; //value && (bulstat9(value) || bulstat13(value)) ? undefined : 'APP.COMMON.VALIDATION.VAT';
  }
  return undefined;
}

export function matchPasswords(password, message) {
  return value => value === password ? undefined : message;
}

export const regexEmail = /^[^\s]+[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?[^\s]+$/;
export const regexPhone = /^[0-9+]+$/;
export const regexCommissionPercent=/^[0-9.]+$/;

const regexIban = {
  AD: /^AD[0-9]{2}[0-9]{4}[0-9]{4}[A-Z0-9]{12}$/,
  AE: /^AE[0-9]{2}[0-9]{3}[0-9]{16}$/,
  AL: /^AL[0-9]{2}[0-9]{8}[0-9A-Z]{16}$/,
  AT: /^AT[0-9]{2}[0-9]{5}[0-9]{11}$/,
  AZ: /^AZ[0-9]{2}[A-Z]{4}[0-9A-Z]{20}$/,
  BA: /^BA[0-9]{2}[0-9]{3}[0-9]{3}[0-9]{8}[0-9]{2}$/,
  BE: /^BE[0-9]{2}[0-9]{3}[0-9]{7}[0-9]{2}$/,
  BG: /^BG[0-9]{2}[A-Z]{4}[0-9]{4}[0-9]{2}[A-Z0-9]{8}$/,
  BH: /^BH[0-9]{2}[A-Z]{4}[A-Z0-9]{14}$/,
  BR: /^BR[0-9]{2}[0-9]{8}[0-9]{5}[0-9]{10}[A-Z]{1}[A-Z0-9]{1}$/,
  BY: /^BY[0-9]{2}[A-Z0-9]{4}[0-9]{4}[A-Z0-9]{16}$/,
  CH: /^CH[0-9]{2}[0-9]{5}[A-Z0-9]{12}$/,
  CR: /^CR[0-9]{2}[0-9]{4}[0-9]{14}$/,
  CY: /^CY[0-9]{2}[0-9]{3}[0-9]{5}[A-Z0-9]{16}$/,
  CZ: /^CZ[0-9]{2}[0-9]{4}[0-9]{6}[0-9]{10}$/,
  DE: /^DE[0-9]{2}[0-9]{8}[0-9]{10}$/,
  DK: /^DK[0-9]{2}[0-9]{4}[0-9]{9}[0-9]{1}$/,
  DO: /^DO[0-9]{2}[A-Z0-9]{4}[0-9]{20}$/,
  EE: /^EE[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{11}[0-9]{1}$/,
  ES: /^ES[0-9]{2}[0-9]{4}[0-9]{4}[0-9]{1}[0-9]{1}[0-9]{10}$/,
  FI: /^FI[0-9]{2}[0-9]{6}[0-9]{8}$/,
  FO: /^FO[0-9]{2}[0-9]{4}[0-9]{9}[0-9]{1}$/,
  FR: /^FR[0-9]{2}[0-9]{5}[0-9]{5}[A-Z0-9]{11}[0-9]{2}$/,
  GB: /^GB[0-9]{2}[A-Z0-9]{4}[0-9]{6}[0-9]{8}$/,
  GE: /^GE[0-9]{2}[A-Z]{2}[0-9]{16}$/,
  GI: /^GI[0-9]{2}[A-Z]{4}[A-Z0-9]{15}$/,
  GL: /^GL[0-9]{2}[0-9]{4}[0-9]{9}[0-9]{1}$/,
  GR: /^GR[0-9]{2}[0-9]{3}[0-9]{4}[A-Z0-9]{16}$/,
  GT: /^GT[0-9]{2}[A-Z0-9]{4}[A-Z0-9]{20}$/,
  HR: /^HR[0-9]{2}[0-9]{7}[0-9]{10}$/,
  HU: /^HU[0-9]{2}[0-9]{3}[0-9]{4}[0-9]{1}[0-9]{15}[0-9]{1}$/,
  IE: /^IE[0-9]{2}[A-Z]{4}[0-9]{6}[0-9]{8}$/,
  IL: /^IL[0-9]{2}[0-9]{3}[0-9]{3}[0-9]{13}$/,
  IQ: /^IQ[0-9]{2}[A-Z]{4}[0-9]{3}[0-9]{12}$/,
  IS: /^IS[0-9]{2}[0-9]{4}[0-9]{2}[0-9]{6}[0-9]{10}$/,
  IT: /^IT[0-9]{2}[A-Z]{1}[0-9]{5}[0-9]{5}[A-Z0-9]{12}$/,
  JO: /^JO[0-9]{2}[A-Z]{4}[0-9]{4}[A-Z0-9]{18}$/,
  KW: /^KW[0-9]{2}[A-Z]{4}[A-Z0-9]{22}$/,
  KZ: /^KZ[0-9]{2}[0-9]{3}[A-Z0-9]{13}$/,
  LB: /^LB[0-9]{2}[0-9]{4}[A-Z0-9]{20}$/,
  LC: /^LC[0-9]{2}[A-Z]{4}[A-Z0-9]{24}$/,
  LI: /^LI[0-9]{2}[0-9]{5}[A-Z0-9]{12}$/,
  LT: /^LT[0-9]{2}[0-9]{5}[0-9]{11}$/,
  LU: /^LU[0-9]{2}[0-9]{3}[A-Z0-9]{13}$/,
  LV: /^LV[0-9]{2}[A-Z]{4}[A-Z0-9]{13}$/,
  MC: /^MC[0-9]{2}[0-9]{5}[0-9]{5}[A-Z0-9]{11}[0-9]{2}$/,
  MD: /^MD[0-9]{2}[A-Z0-9]{2}[A-Z0-9]{18}$/,
  ME: /^ME[0-9]{2}[0-9]{3}[0-9]{13}[0-9]{2}$/,
  MK: /^MK[0-9]{2}[0-9]{3}[A-Z0-9]{10}[0-9]{2}$/,
  MR: /^MR[0-9]{2}[0-9]{5}[0-9]{5}[0-9]{11}[0-9]{2}$/,
  MT: /^MT[0-9]{2}[A-Z]{4}[0-9]{5}[A-Z0-9]{18}$/,
  MU: /^MU[0-9]{2}[A-Z]{4}[0-9]{2}[0-9]{2}[0-9]{12}[0-9]{3}[A-Z]{3}$/,
  NL: /^NL[0-9]{2}[A-Z]{4}[0-9]{10}$/,
  NO: /^NO[0-9]{2}[0-9]{4}[0-9]{6}[0-9]{1}$/,
  PK: /^PK[0-9]{2}[A-Z]{4}[A-Z0-9]{16}$/,
  PL: /^PL[0-9]{2}[0-9]{8}[0-9]{16}$/,
  PS: /^PS[0-9]{2}[A-Z]{4}[A-Z0-9]{21}$/,
  PT: /^PT[0-9]{2}[0-9]{4}[0-9]{4}[0-9]{11}[0-9]{2}$/,
  QA: /^QA[0-9]{2}[A-Z]{4}[A-Z0-9]{21}$/,
  RO: /^RO[0-9]{2}[A-Z]{4}[A-Z0-9]{16}$/,
  RS: /^RS[0-9]{2}[0-9]{3}[0-9]{13}[0-9]{2}$/,
  SA: /^SA[0-9]{2}[0-9]{2}[A-Z0-9]{18}$/,
  SC: /^SC[0-9]{2}[A-Z]{4}[0-9]{2}[0-9]{2}[0-9]{16}[A-Z]{3}$/,
  SE: /^SE[0-9]{2}[0-9]{3}[0-9]{16}[0-9]{1}$/,
  SI: /^SI[0-9]{2}[0-9]{5}[0-9]{8}[0-9]{2}$/,
  SK: /^SK[0-9]{2}[0-9]{4}[0-9]{6}[0-9]{10}$/,
  SM: /^SM[0-9]{2}[A-Z]{1}[0-9]{5}[0-9]{5}[A-Z0-9]{12}$/,
  ST: /^ST[0-9]{2}[0-9]{4}[0-9]{4}[0-9]{11}[0-9]{2}$/,
  SV: /^SV[0-9]{2}[A-Z]{4}[0-9]{20}$/,
  TL: /^TL[0-9]{2}[0-9]{3}[0-9]{14}[0-9]{2}$/,
  TN: /^TN[0-9]{2}[0-9]{2}[0-9]{3}[0-9]{13}[0-9]{2}$/,
  TR: /^TR[0-9]{2}[0-9]{5}[A-Z0-9]{1}[A-Z0-9]{16}$/,
  UA: /^UA[0-9]{2}[0-9]{6}[A-Z0-9]{19}$/,
  VA: /^VA[0-9]{2}[0-9]{3}[0-9]{15}$/,
  VG: /^VG[0-9]{2}[A-Z]{4}[0-9]{16}$/,
  XK: /^XK[0-9]{2}[0-9]{4}[0-9]{10}[0-9]{2}$/
};