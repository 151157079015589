import React from 'react';
import Link from '../../components/LinkLng';
import { useLanguageContext } from '../../contexts/languageContext';
import AppleIcon from '../../images/app_store_icon.png';
import AndroidIcon from '../../images/google_play_icon.png';
import Linkedin from '../../images/linkedin.png';

export default function Footer() {
  const { gettext } = useLanguageContext();

  return (
    <footer className="site-footer section-fullwidth">
      <div className="row">
        <div className="column small-12 medium-3">
          <h2 className="site-logo"><Link to="/">Digitoll</Link></h2>
        </div>

        <div className="column small-12 medium-3">
          <div className="widget">
            <h3 className="widget-title">{gettext('Продукти')}</h3>
            <ul className="list-minimal">
              <li className="list-item"><Link to="/about-e-vignette">{gettext('Какво е Е-винетка')}</Link></li>
              <li className="list-item"><Link to="/annual-e-vignette">{gettext('Годишни винетки')}</Link></li>
              <li className="list-item"><Link to="/quarterly-e-vignette">{gettext('Тримесечни винетки')}</Link></li>
              <li className="list-item"><Link to="/monthly-e-vignette">{gettext('Месечни винетки')}</Link></li>
              <li className="list-item"><Link to="/weekly-e-vignette">{gettext('Седмични винетки')}</Link></li>
              <li className="list-item"><Link to="/weekend-e-vignette">{gettext('Уикенд винетки')}</Link></li>
              <li className="list-item"><Link to="/about-route-pass">{gettext('Маршрутни карти за ППС-та >3.5т')}</Link></li>
              <li className="list-item"><Link to="/about-toll-system">{gettext('Тол такса')}</Link></li>
              <li className="list-item"><Link to="/about-delicts">{gettext('Нарушения')}</Link></li>
            </ul>
          </div>
        </div>

        <div className="column small-12 medium-3">
          <div className="widget">
            <h3 className="widget-title">{gettext('Ресурси')}</h3>
            <ul className="list-minimal">
              <li className="list-item"><Link to="/prices">{gettext('Цени')}</Link></li>
              <li className="list-item"><Link to="/check-for-validity-of-e-vignette">{gettext('Проверка на винетки')}</Link></li>
              <li className="list-item"><Link to="/how-to-buy-e-vignette-from-digitoll">{gettext('Как да купя')}</Link></li>
              <li className="list-item"><Link to="/how-to-return-OBU">{gettext('Как да върна ОБУ')}</Link></li>
              <li className="list-item"><Link to="/terms-and-conditions">{gettext('Общи условия')}</Link></li>
              <li className="list-item"><Link to="/privacy-policy">{gettext('Политика за защита на личните данни')}</Link></li>
              <li className="list-item"><Link to="/download-documents">{gettext('Документи за сваляне')}</Link></li>
              <li className="list-item"><Link to="/register-steps">{gettext('Регистрация и използване портала на Digitoll')}</Link></li>
            </ul>
          </div>
        </div>

        <div className="column small-12 medium-3">
          <div className="widget">
            <h3 className="widget-title">{gettext('За DigiToll')}</h3>
            <ul className="list-minimal">
              <li className="list-item"><Link to="/about-digitoll">{gettext('Кои сме ние')}</Link></li>
              { /* <li className="list-item"><Link to="/partners">{gettext('APP.WEB.FOOTER.PARTNERS_NETWORK')}</Link></li> */ }
              { /* <li className="list-item"><Link to="/news">{gettext('Новини')}</Link></li> */ }
              { /* <li className="list-item"><Link to="/road-information">{gettext('Пътна обстановка')}</Link></li>*/}
            </ul>
          </div>
          <br />
          <div className="widget">
            <h3 className="widget-second-title">{gettext('APP.COMMON.COMMON.CONTACTS')}</h3>
            <ul className="list-minimal list-minimal-icons">
              <li className="list-item" title={gettext('Е-mail за търговска информация')}>
                <div className="icon icon-email icon-white"></div>
                <a href="mailto:info@digitoll.bg">info@digitoll.bg</a>
              </li>
              <li className="list-item" title={gettext('Е-mail за проблеми и технически въпроси')}>
                <div className="icon icon-email icon-white"></div>
                <a href="mailto:support@digitoll.bg">support@digitoll.bg</a>
              </li>
              <li className="list-item">
                <div className="icon icon-phone icon-white"></div>
                <div>
                  <a href="tel:+35970035033">+359 700 35033</a>
                </div>
              </li>
              <li className="list-item">
                <Link to="http://www.facebook.com/pg/DigiToll" external><span className="icon icon-facebook icon-white" alt=""/></Link>
                <Link to="http://www.linkedin.com/company/digitoll-smart-infrastructure" external><img className="linked-in-icon" src={Linkedin} alt=""/></Link>
              </li>
            </ul>
          </div>
        </div>

      </div> 

      <div className="row">
        <div className="column">
          <div className="text-center site-copyright">
            <div className="list-item app-buttons">
              <a href="https://apps.apple.com/bg/app/digitoll/id1489173249" className="">
                <img src={AppleIcon} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.hyperaspect.digitoll" className="">
                <img src={AndroidIcon} alt="" />
              </a>
            </div>
            <p>
              <span>© Copyright 2019</span>
              <span>Digitoll.bg</span>
              <span>All rights reserved</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}